import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  MembershipDirectory,
  SecondaryPagesHero,
  Seo,
} from "../../components/elements"
import { SectionHeading } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const ResearchPage = ({ data, pageContext }) => {
  const page = data.datoCmsCancerResearchPage
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo title={page.title} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", "full", 3 / 4]}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>

        {pageContext.slug === "membership" && (
          <Section bg="lightgray">
            <Container>
              <Flex flexWrap="wrap" justifyContent="center">
                <SectionHeading textAlign="center" id="members">
                  Members
                </SectionHeading>

                <Box width="full">
                  <MembershipDirectory />
                </Box>
              </Flex>
            </Container>
          </Section>
        )}
      </Main>
    </Layout>
  )
}

export default ResearchPage

export const query = graphql`
  query researchPageQuery($slug: String!) {
    datoCmsCancerResearchPage(slug: { eq: $slug }) {
      id
      contentNode {
        childMarkdownRemark {
          html
          id
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fit: "crop", crop: "focalpoint" }
        )
      }
      meta {
        status
      }
      title
      slug
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
